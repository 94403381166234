
.new_home_landing{
  :root {
    --bs-primary-rgb: rgb(23, 67, 99);
    --bs-secondary-rgb: rgb(209, 217, 224);
    --grey: #E9ECEF;
    --gray-600: #6C757D;
  }
  h1, h2, h3, h4, h5, h6{
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .text-blue {
    color: #0AA2C0;
  }
  
  .text-green {
    color: #198754;
  }
  
  .btn-danger {
    --bs-btn-bg: #A30234;
  }
  
  .bg-secondary {
    background-color: #D1D9E0 !important;
  }
  
  .badge {
    font-size: 12px;
    border-radius: 2px;
    font-weight: 500;
    padding: 4px 8px;
  }
  
  #topnavbar .badge {
    color: #174363;
  }
  
  .pagination {
    --bs-pagination-active-bg: #174363;
    --bs-pagination-padding-x: 9px;
    --bs-pagination-padding-y: 5px;
  }
  
  .nav {
    --bs-nav-link-color: #456982;
  }
  
  .nav-tabs {
    --bs-nav-tabs-link-active-color: #174363;
  }
  
  .g-40, .gy-40 {
    --bs-gutter-y: 2.5rem;
  }
  
  .g-32, .gy-32 {
    --bs-gutter-y: 32px;
  }
  
  .px-32 {
    padding: 0 32px;
  }
  
  .modal {
    --bs-modal-width: 630px;
  }
  
  .nav-tabs .active {
    font-weight: 700;
  }
  
  #topnavbar h4, #topnavbar .h4 {
    align-items: center;
    display: flex;
    margin-bottom: 0;
    position: relative;
  }
  #topnavbar .badge {
    margin-left: 8px;
  }
  @media (max-width: 767.98px) {
    #topnavbar .badge {
      position: absolute;
      right: 0;
      top: -20px;
      margin: 0;
    }
  }
  #topnavbar .btn {
    display: flex;
    align-items: center;
  }
  #topnavbar .btn img {
    margin-left: 8px;
  }
  
  .mt-32 {
    margin-top: 32px;
  }
  
  .link-underline {
    color: #A30234;
    text-decoration-color: #A30234 !important;
  }
  
  .modal {
    z-index: 1070;
  }
  
  .nav-tabs {
    margin-bottom: 24px;
  }
  
  .row.g-16 > * {
    margin-top: 16px;
  }
  
  .mt-40 {
    margin-top: 24px;
  }
  
  .input-phone .dropdown-toggle::after {
    display: none;
  }
  
  .input-phone button {
    background: #E9ECEF;
  }
  
  .text-bg-secondary {
    background-color: #A2B4C1 !important;
  }
  
  form label {
    font-weight: 400;
  }
  
  .iti {
    width: 100%;
  }
  
  .iti input {
    height: 38px;
    Width: 100%;
  }
  
  .text-warning {
    color: #FD7E14 !important;
  }
  
  .red-dot {
    width: 8px;
    height: 8px;
    display: block;
    background: #BA0C2F;
    border-radius: 50%;
  }
  
  #topnavbar .badge {
    background: #D1D9E0;
    border-radius: 4px;
  }
  
  .fs-14 {
    font-size: 14px !important;
  }
  
  #drawer-view-org #table-users th:nth-child(5) {
    min-width: 90px;
  }
  
  .trash {
    cursor: pointer;
  }
  
  
  .date-picker {
  }
  
  .dataTables_wrapper {
    overflow: scroll;
  }
  
  .drawer-close {
    cursor: pointer;
  }
  
  @media (max-width: 767.98px) {
    .topnav .form-check-label {
      margin-top: 10px;
      font-size: 14px;
    }
  }
  
  .cover-img {
    object-position: top left;
  }
  
  .upload-container {
    cursor: pointer;
  }
  
  h1, .h1, h2, .h2, h3, .h3 {
    font-weight: 700;
  }
  
  #header {
    min-height: 90px;
    display: flex;
    align-items: center;
  }
  @media (max-width: 991px){
    #header{
      min-height: 70px;
    }
    #header .header-logo{
      width: 20%;
    }
    #header .btn{
      padding: 12px;
    }
  }
  @media (min-width: 768px) {
    #header{
      padding: 0 95px;
    }
  }
  #header .btn-outline-primary{
    color: #2F80ED;
    border: 1px solid #2F80ED;
    text-align: center;
  }
  #header .btn-primary{
    background: #2F80ED;
    color: #FFF;
    margin-left: 12px;
    text-align: center;
  }

  .btn {
    text-align: center;
    display: inline-flex;
  }
  
  .button-block {
    margin-top: 150px;
  }
  
  .btn {
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    padding: 12px 32px;
    border: none;
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    justify-content: center;
  }
  @media (min-width: 768px) {
    .btn {
      border-radius: 100px;
      padding: 16px 56px;
    }
  }
  .btn-outline-primary {
    border: 1px solid #FFF;
    color: #FFF;
    background: transparent;
  }
  .btn-outline-primary:hover {
    background: #FFF;
    color: #2F80ED;
  }
  
  .btn-primary {
    background: #FFF;
    color: #0089FF;
  }
  
  @media (min-width: 1024px) {
    .container-small {
      max-width: 1250px;
      margin: auto;
    }
  }
  
  #banner {
    background: url("img/Single-Family.png") no-repeat left bottom;
    background-size: cover;
    color: #FFF;
    position: relative;
    padding-bottom: 50px;
  }
  .col-lg-6{
    width: 100%;
  }
  #banner .col-lg-6{
    margin-bottom: 24px;
  }
  @media (min-width: 992px) {
    #banner .col-lg-6{
      margin-bottom: 0;
    }
  }
  #banner h1, #banner .h1 {
    font-size: 32px;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  @media (min-width: 768px) {
    #banner h1, #banner .h1 {
      font-size: 48px;
    }
  }
  #features {
    text-align: center;
    padding-bottom: 50px;
  }
  #features .badge {
    border-radius: 100px;
    background: #F90;
    color: #FFF;
    text-transform: uppercase;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-left: 16px;
  }
  #features h2, #features .h2 {
    font-size: 32px;
    margin-bottom: 18px;
  }
  @media (min-width: 768px){
    #features h2, #features .h2 {
      font-size: 45px;
      margin-bottom: 24px;
    }
  }
  #features h3, #features .h3 {
    color: #1C3150;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    display: flex;
    align-items: center;
  }
  @media (min-width: 768px){
    #features h3, #features .h3 {
      font-size: 35px;
      line-height: 50px;
    }
  }
  #features p {
    font-size: 18px;
    line-height: 32px;
  }
  
  .mb-56 {
    margin-bottom: 56px;
  }
  
  section {
    padding: 36px 0;
  }
  @media (min-width: 768px) {
    section {
      padding: 72px 0;
    }
  }
  .video-frame {
    background: url("img/frames.png") no-repeat center top;
    background-size: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 27px 27px 170px;
  }
  
  .video-frame iframe {
    border-radius: 10px;
    height: 54vw;
    max-height: 450px;
  }
  @media (min-width: 500px) {
    .video-frame iframe {
      height: 58vw;
    }
  }
  @media (min-width: 992px) {
    .video-frame iframe {
      height: 27vw;
    }
  }
  #footer {
    background: url("img/footer.png") no-repeat center;
    padding-top: 80px;
    color: #FFF;
    font-size: 14px;
    line-height: 24px;
    background-size: cover;
  }
  #footer hr {
    background: #294875;
    margin: 0;
  }
  #footer #copyright {
    padding: 24px 12px;
    color: #6C92CB;
  }
  @media (min-width: 768px) {
    #footer #copyright{
  
    }
  }
  #footer li {
    list-style-type: none;
    margin-bottom: 16px;
  }
  #footer a {
    font-size: 16px;
    color: #FFF;
    font-weight: 600;
    text-decoration: none;
    display: flex;
  }
  #footer .socials {
    display: flex;
    margin: 0 -12px;
    margin-left: auto;
  }
  #footer hr{
    border: none;
    height: 1px;
  }
  @media (max-width: 767px){
    #footer .socials {
      margin: 10px -12px;
    }
    #footer #copyright .row{
      justify-content: center;
    }
  }
  #footer .socials li {
    margin: 0 12px 0;
  }
  #footer .container-small {
    padding-bottom: 72px;
  }
  #footer .logo-footer {
    margin-bottom: 16px;
  }
  #footer .btn {
    color: #FFF;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 100px;
    background: #2F80ED;
  }
  
  .fw-600 {
    font-weight: 600;
  }
  
  .cta {
    background: #fff;
    padding: 72px 0px;
  }
  .cta h2, .cta .h2 {
    text-transform: capitalize;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    .cta h2, .cta .h2 {
      font-size: 35px;
      margin-bottom: 30px;
      line-height: 50px;
    }
    .cta.bg-image .cta-section h2 {
      font-size: 45px;
    }
  }
  .cta.bg-image .cta-section {
    padding: 60px 20px 0 60px;
    position: relative;
  }
  @media (max-width: 767px) {
    .cta.bg-image .cta-section {
      padding: 30px 30px;
      position: relative;
    }
  }
  .cta.bg-image .cta-section .btn {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .cta.bg-image .col-md-6{
    position: relative;
    z-index: 2;
  }
  .cta img {
    mix-blend-mode: multiply;
    object-fit: cover;
  }
  @media (max-width: 767px) {
    .cta img{
      position: absolute;
      z-index: 1;
      width: 90%;
      right: 10px;
      bottom: 0;
    }
  }
  @media (min-width: 768px) {
    .cta .buttons {
      display: flex;
      flex-direction: column;
    }
    .cta .buttons .btn {
      margin-top: 0;
      min-width: 100px;
    }
  }
  @media (min-width: 1200px) {
    .cta .buttons {
      flex-direction: row;
    }
    .cta .buttons .btn {
      min-width: 260px;
      margin: 0 6px;
    }
  }
  .w-100{
    width: 100%;
  }
  .cta .buttons .btn-secondary {
    border: 1px solid #000;
    color: #FFF;
  }
  .cta .buttons .btn {
    margin-top: 16px;
    min-width: 260px;
    padding: 20px 46px;
  }
  
  .cta-section {
    background: #2F80ED;
    padding: 60px;
    color: #FFF;
    border-radius: 16px;
  }
  @media (max-width: 767px) {
    .cta-section {
      text-align: center;
      padding: 24px;
    }
  }
  .container{
    margin: auto;
    padding: 0 12px;
  }
  .container-fluid{
    width: 100%;
    padding: 0 12px;
    margin-right: auto;
    margin-left: auto;
  }
  .text-start {
    text-align: left;
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
  .row{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }
  .row > *{
    max-width: 100%;
    padding: 0 12px;
  }
  .col {
    flex: 100%;
  }
  .container-small{
    width: 100%;
    padding: 0 12px;
    @media (min-width: 992px){
      max-width: 1250px;
      margin: auto;
    }
  }
  @media (min-width: 768px){
    .col{
      flex: 1 0 0%;
    }
    .pe-5 {
      padding-right: 3rem !important;
    }
    .ps-5 {
      padding-left: 3rem !important;
    }
  }
  .d-flex{
    display: flex;
  }
  .justify-content-between{
    justify-content: space-between;
  }
  .align-items-center{
    align-items: center;
  }
  .ms-auto{
    margin-left: auto;
  }
  .align-items-end{
    align-items: end;
  }
  @media (min-width: 768px) {
    .col-md-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-md-6{
      flex: 0 0 auto;
      width: 50%;
    }
  }
  @media (min-width: 992px) {
    .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }
  @media (min-width: 1600px) {
    .container {
      max-width: 1280px;
    }
  }
  .fs-22 {
    font-size: 18px;
    line-height: 28px;
  }
  @media (min-width: 768px) {
    .fs-22 {
      font-size: 22px;
      line-height: 38px;
    }
  }
  
  .mb-56 {
    margin-bottom: 56px;
  }
  
  /** TESTIMONIALS */
  #testimonials {
    background: #F0F8FF;
  }
  
  #testimonials .slick-track {
    /*display: flex;*/
    /*width: 100%;*/
    /*justify-content: space-between;*/
  }
  
  #testimonials .testimonials-nav .slick-track {
    /*width: 100% !important;*/
  }
  
  #testimonials .slick-slide {
    height: inherit !important;
  }
  
  #testimonials-block {
    max-width: 100%;
    margin-top: 56px;
  }
  
  #testimonials-block .testimonials {
    margin: auto;
  }
  
  .testimonial-short-info p {
    margin: 0;
    font-size: 12px;
  }
    
  .testimonial-short-info{
    .location{
      display: flex;
    }
  }

  .testimonial-short-info .testimonial-avatar img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .testimonial-short-info{
    &.active{
      border-bottom: 4px solid #2F80ED;;
    }
  }

  .testimonial-short-info .testimonial-avatar {
    padding-right: 14px;
  }
  
  .testimonials-nav{
    padding-bottom: 72px;
  }

  .location svg{
    position: relative;
    left:-2px;
    margin-right: 2px;
  }

  .testimonials-nav .slick-current .testimonial-short-info {
    border-bottom: 4px solid #2F80ED;
  }
  #footer ul{
    padding-left: 0;
  }
  .testimonials-nav .testimonial-short-info-inner {
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 768px) {
    .testimonials-nav .testimonial-short-info-inner {
      padding-bottom: 16px;
      margin-right: 20px;
    }
  }
  
  .testimonials-nav {
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .testimonial-short-info-inner {
    flex-direction: column;
  }
  
  .testimonial-short-info {
    display: inline-block;
  }
  
  @media (max-width: 767px) {
    .testimonial-short-info-inner {
      text-align: center;
    }
    .testimonial-logo {
      margin-bottom: 16px;
    }
    .testimonial-short-info {
      display: block;
    }
  }
  @media (min-width: 768px) {
    .testimonial-short-info-inner {
      flex-direction: row;
    }
  }
  .testimonials .testimonial-short-info {
    margin-top: 32px;
  }
  .testimonials{
  }
  
  .testimonial-short-info .author-name {
    font-weight: 600;
    margin-bottom: 6px;
    color: #23262F;
  }
  
  .testimonial-short-info .location {
    margin-top: 8px;
  }
  
  .testimonial-short-info .author-title {
    text-transform: uppercase;
  }
  
  .testimonial-short-info .location,
  .testimonial-short-info .author-title {
    color: #6C92CB;
  }
  
  .testimonial-image-right img {
    width: 100%;
  }
  
  .testimonial-text {
    color: #1C3150;
    font: 400 18px/32px "Poppins";
    text-align: center;
    margin: 0;
  }
  @media (min-width: 768px){
    .testimonial-text {
      font: 400 24px/40px "Poppins";
    }
  }
  
  .testimonial-card {
    margin: auto;
    border-radius: 10px;
    /*
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
    */
    height: 100%;
    border: none;
  }
  
  .slick-next:before, .slick-prev:before {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    border-left: 2px solid var(--blue-50);
    border-top: 2px solid var(--blue-50);
    opacity: 1;
  }

  .swiper-button-prev{
    color: rgba(108, 146, 203, 1);
    &:after{
      font-size: 30px;
    }
  }

  .swiper-button-next{
    color: rgba(108, 146, 203, 1);
    &:after{
      font-size: 30px;
    }
  }
  
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  @media (min-width: 768px){
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
  
  .slick-prev:before {
    transform: rotate(-45deg);
    left: 0;
  }
  
  .slick-next:before {
    transform: rotate(135deg);
    right: 0;
  }
  
  .testimonial-card .card-body {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: #FFF;
    border-radius: 10px;
    border: none;
    min-height: 340px;
  }
  .swiper-wrapper{
    align-items: center;
  }
  @media (min-width: 768px){
    .testimonial-card .card-body {
      padding: 56px 104px;
    }
  }
  
  #dashboard-sidebar {
    width: 270px;
    background: var(--bs-primary-rgb);
    margin: 0;
    transition: 0.4s;
  }
  #dashboard-sidebar .dots {
    display: flex;
  }
  #dashboard-sidebar .navbar-items span {
    display: inline-block;
  }
  #dashboard-sidebar.collapse {
    width: 80px;
  }
  #dashboard-sidebar.collapse .dots,
  #dashboard-sidebar.collapse .profile-info {
    display: none;
  }
  @media (max-width: 991px) {
    #dashboard-sidebar.collapse {
      width: 60px;
    }
    #dashboard-sidebar.collapse #sidebar-top {
      padding: 16px 8px 24px;
      justify-content: center;
    }
    #dashboard-sidebar.collapse .menu-profile-container {
      justify-content: center;
    }
    #dashboard-sidebar.collapse .navbar-items {
      padding: 0 6px;
    }
    #dashboard-sidebar.collapse .dots,
    #dashboard-sidebar.collapse .profile-info {
      display: none;
    }
  }
  #dashboard-sidebar.collapse .navbar-items span {
    display: none;
  }
  #dashboard-sidebar.collapse .small_logo {
    display: block;
  }
  #dashboard-sidebar.collapse .large_logo {
    display: none;
  }
  #dashboard-sidebar.collapse .collapse-icon {
    position: absolute;
    right: -30px;
    transform: rotate(180deg);
  }
  #dashboard-sidebar.collapse .collapse-icon path {
    stroke: #174363;
  }
  #dashboard-sidebar.collapse #sidebar-bottom small, #dashboard-sidebar.collapse #sidebar-bottom .small {
    position: absolute;
    right: 0;
    font-size: 12px;
    top: 0;
    background: #FFF;
    min-width: 10px;
    height: 18px;
    color: #A30234;
    border-radius: 50%;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }
  #dashboard-sidebar .small_logo {
    display: none;
  }
  
  #sidebar-version {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #748EA1;
    padding-bottom: 24px;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  #sidebar-version-item {
    padding-bottom: 8px;
  }
  
  #sidebar-version-item.collapsed {
    padding-bottom: 0;
  }
  
  .navbar-items {
    padding: 0 24px;
    margin: -4px 0 0;
  }
  .navbar-items li {
    list-style: none;
    margin: 4px 0;
  }
  .navbar-items a {
    padding: 8px 12px;
    text-decoration: none;
    color: #FFF;
    transition: 0.4s;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-weight: 600;
  }
  .navbar-items a span {
    transition: 0.4s;
    opacity: 1;
  }
  .navbar-items a.active, .navbar-items a:hover {
    background: #0E283B;
  }
  .navbar-items a img {
    margin-right: 10px;
  }
  
  #sidebar-top {
    padding: 16px 24px 46px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  #sidebar-top span {
    cursor: pointer;
    margin-left: 10px;
  }
  
  #sidebar-bottom {
    margin-top: auto;
  }
  #sidebar-bottom li {
    list-style: none;
  }
  #sidebar-bottom small, #sidebar-bottom .small {
    font-size: 11px;
    margin-left: auto;
  }
  
  @media (max-width: 768px) {
    .navbar-items a {
      font-size: 14px;
    }
  }
  #dashboard {
    width: 100%;
    padding: 36px 24px;
    overflow-x: hidden;
    background: #FFF;
  }
  
  .table th {
    background: var(--grey);
    padding: 16px 24px;
  }
  .table td {
    padding: 8px 24px;
    font-size: 14px;
    vertical-align: middle;
    height: 52px;
  }
  .table .btn {
    padding: 3px 9px;
    font-size: 14px;
  }
  
  table.dataTable {
    margin: 0 !important;
  }
  
  .company_small {
    font-size: 12px;
    color: #6C757D;
    display: block;
  }
  
  table.dataTable.table-striped > tbody > tr.odd > * {
    box-shadow: inset 0 0 0 9999px #F8F9FA;
  }
  
  .dataTables_paginate {
    bottom: 16px;
    right: 24px;
  }
  
  .drawer {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    transform: translateX(calc(100% + 50px));
    transition: 0.6s;
    opacity: 0;
    z-index: -1;
  }
  @media (max-width: 991.98px) {
    .drawer .btn-save {
      position: fixed;
      bottom: 0;
      padding: 8px 16px;
      background: #FFF;
      width: 100%;
      left: 0;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
      text-align: right;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media (min-width: 992px) {
    .drawer {
      width: 60%;
    }
  }
  .drawer.active {
    z-index: 1060;
    opacity: 1;
    transform: translateX(0);
  }
  .drawer .close {
    position: absolute;
    top: 10px;
    left: -60px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 991px) {
    .drawer .close {
      top: 0;
      left: 0;
      width: 48px;
      height: 48px;
      cursor: pointer;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .drawer.bg {
    z-index: 1050;
  }
  
  .drawer-content {
    background: #FFF;
    overflow: scroll;
    height: 100%;
    padding: 16px 16px 54px;
  }
  @media (min-width: 992px) {
    .drawer-content {
      padding: 32px;
      padding-bottom: 32px;
    }
  }
  .drawer-content h4, .drawer-content .h4 {
    margin-bottom: 24px;
  }
  
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    display: none;
  }
  .overlay.active {
    display: block;
  }
  
  #drawer-add-org-user,
  #drawer-view-org-user,
  #drawer-view-job {
    z-index: 1061;
  }
  
  #arrow-back {
    cursor: pointer;
    display: inline-block;
  }
  #arrow-back img {
    margin-right: 10px;
  }
  
  #drawer-shared-job sup {
    color: #198754;
    font-size: 14px;
    margin-left: 8px;
  }
  
  @media (max-width: 991px) {
    .btn_drawer {
      position: fixed;
      bottom: 0;
      left: 0;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
      background: #FFF;
      padding: 8px 16px;
      width: 100%;
      display: flex;
      justify-content: end;
    }
    #drawer-upload .nav-link,
    #drawer-view-job .nav-link {
      font-size: 14px;
    }
    #drawer-upload .buttons {
      width: 100%;
      display: flex;
      align-items: center;
    }
    #drawer-upload .buttons .btn {
      margin-left: auto !important;
    }
  }
  .button-options {
    margin: 0;
    position: absolute;
    right: 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background: #FFF;
    top: 40px;
    padding: 8px 0;
    transform: translateX(calc(100% + 24px));
    transition: 0.4s;
    z-index: -1;
  }
  .button-options li {
    list-style: none;
    padding: 8px 16px;
    text-align: left;
    transition: 0.4s;
    cursor: pointer;
  }
  .button-options li:hover {
    background: var(--grey);
  }
  
  .dropdown-container {
    width: 220px;
    position: relative;
    text-align: right;
  }
  @media (max-width: 991.98px) {
    .dropdown-container {
      overflow: hidden;
      position: absolute;
      right: 5px;
      width: 35px;
      background: var(--bs-primary-rgb);
      top: 5px;
      border-radius: 5px;
      height: 35px;
    }
    .dropdown-container.active {
      overflow: visible;
    }
    .dropdown-container.active .button-options {
      width: 200px;
    }
  }
  .dropdown-container .btn {
    padding-right: 30px;
  }
  .dropdown-container .btn:before {
    right: 11px;
    transform: rotate(-135deg);
    position: absolute;
    top: -4px;
    bottom: 0;
    margin: auto;
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-left: 1px solid #FFF;
    border-top: 1px solid #FFF;
  }
  
  .dropdown-container.active .button-options {
    z-index: 5;
    transform: translateX(0);
  }
  
  .checkbox .cr {
    position: absolute;
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    float: left;
    left: -29px;
    top: 1px;
  }
  
  .checkbox label {
    position: relative;
  }
  
  .checkbox label input[type=checkbox]:checked {
    display: none;
  }
  
  .checkbox label input[type=checkbox]:checked + .cr {
  }
  
  .checkbox label input[type=checkbox]:disabled + .cr {
    opacity: 0.5;
  }
  
  .form-check .form-check-input {
    margin-right: 10px;
  }
  
  .modal .modal-content {
    overflow: visible;
  }
  .modal .btn-close {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .modal-header {
    justify-content: center;
    border: none;
    padding-bottom: 0;
  }
  
  .modal-footer {
    border: none;
  }
  
  .modal-title {
    font-size: 24px;
    font-weight: 700;
  }
  
  .navbar-items .menu-profile {
    margin: 32px 0;
  }
  .navbar-items .menu-profile.active .button-options {
    opacity: 1;
    z-index: 1;
  }
  
  .menu-profile-container {
    display: flex;
    cursor: pointer;
    position: relative;
    width: 100%;
    justify-content: center;
  }
  .menu-profile-container .button-options {
    top: -95px;
    left: 40px;
    right: auto;
    transform: none;
    width: 150px;
    opacity: 0;
  }
  .menu-profile-container .button-options.active {
    opacity: 1;
  }
  .menu-profile-container .button-options li {
    margin: 0;
    padding: 4px 16px;
  }
  
  .menu-profile {
    margin-top: 32px;
    display: flex;
  }
  .menu-profile .profile-info {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-left: 8px;
    color: #FFF;
  }
  .menu-profile .name {
    font-weight: 600;
  }
  
  body {
    font-family: "Poppins", sans-serif;
  }
  
  h3, .h3 {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    line-height: 38px;
  }
  
  /** FAQ ***/
  #faq .accordion-body,
  #faq .accordion-button,
  #faq {
    background: #FFF;
  }
  
  #faq h2, #faq .h2 {
    margin-bottom: 32px;
  }
  @media (min-width: 768px) {
    #faq h2{
      color: #1C3150;
      text-align: center;
      font-family: Poppins;
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px; /* 133.333% */
      text-transform: capitalize;
    }
  }
  
  #faq .top-content p {
    font-size: 22px;
    margin-bottom: 0;
    text-align: center;
  }
  #faq .top-content p a {
    color: #0089FF;
    text-decoration: none;
  }
  
  #faq .top-content .subheading {
    margin-bottom: 32px;
  }
  
  .faq-button {
    margin-top: 64px;
    justify-content: center;
    display: flex;
    margin-bottom: 72px;
  }
  .faq-button .btn {
    background: #2F80ED;
    color: #FFF;
  }
  
  #accordionFAQ {
    margin-top: 56px;
  }
  
  .accordion-button {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    border: none;
  }
  
  @media (min-width: 768px) {
    .accordion-button {
      font-size: 28px;
      line-height: 32px;
    }
  }
  .accordion-body {
    padding-top: 0;
    font-size: 18px;
    line-height: 32px;
  }
  
  .accordion-button:focus {
    outline: none;
    box-shadow: none;
  }
  
  .accordion-button:not(.collapsed) {
    box-shadow: none;
    background-color: transparent;
    color: var(--blue-90);
  }
  
  .accordion-item {
    border: 0;
    border-top: 1px solid #E6E8EC;
    border-bottom: 1px solid #E6E8EC;
    padding-top: 30px;
    padding-bottom: 24px;
  }

  h2{
    font-size: 45px !important;
    font-weight: 700 !important;
    line-height: 60px !important;
    text-align: center;
    margin: 72px 0px 24px !important;
    padding: 0px !important;
    color: rgba(28, 49, 80, 1);

  }

  .h2_title2{
    font-size: 45px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    color: #fff;
    margin-bottom: 30px;
  }

  .h2_title3{
    font-size: 40px;
    font-weight: 700;
    line-height: 51px;
    text-align: left;
    color: #fff;
    margin-bottom: 30px;
  }

  .desc_df2{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 30px;
  }

  .title_f_1{
    font-size: 48px;
    font-weight: 700;
    line-height: 64.8px;
    text-align: left;
    margin-bottom: 30px;
  }

  #banner .container{
    padding-top: 80px;
    margin-bottom: 40px;
  }

  .desc_1_f{
    font-size: 22px;
    font-weight: 400;
    line-height: 38.5px;
    text-align: left;
    margin-bottom: 40px;

  }

  .file_right{
    width: auto;
  }

  .chakra-accordion{
    padding-top: 56px;
  }

  .chakra-accordion__button{
    background: transparent !important;
    padding: 32px 0px 32px;
    outline: unset;
    box-shadow: unset;
  }

  .chakra-collapse{
    margin-bottom: 16px;
    margin-top: -16px;
  }

  .new_a_button{
    color: #1C3150;
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    text-transform: capitalize;
  }
  .new_a_panel{
    color: #1C3150;
    font-size: 18px;
    font-weight: 400;
    line-height: 175%;
    padding-left: 24px;
  }

  .accordion_btn {
    width: 22px;
  }

  .video_image{
    cursor:pointer;
  }

  @media (max-width:768px){
    .location{
      justify-content: center;
    }
  }
  .footer_title{
    margin-bottom: 16px;
  }
  .btn_footer{
    margin-top: 24px;
  }

  @media (max-width:768px){
    .swiper-button-next, .swiper-button-prev{
      display: none !important;
    }
  }

  .bottom_agents{
    padding: 76px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title{
      margin-bottom: 56px;
      color: #2C335C;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: 134%;
      text-transform: capitalize;
    }
    .brands_b{
      display: flex;
      justify-content: space-between;
      width: 710px;
      max-width: 100%;
      flex-wrap: wrap;
      padding: 0px 20px;
    }
  }
}
