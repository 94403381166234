.orange {
    color: #fe805c;
    background-color: transparent;
    font-weight: 600;
}

.green {
    color: green;
    background-color: transparent;
    font-weight: 600;
}

.bold {
    font-weight: 600;
    background-color: transparent;
    color: #2c335c;
}
