body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.image-viewer .images-viewer-react-canvas {
    background: rgba(0, 0, 0, 0.7);
}

@font-face {
    font-family: 'Avenir Black';
    src: url('/src/assets/fonts/AvenirLTStd-Black.otf') format('opentype');
}

@font-face {
    font-family: 'Avenir Book';
    src: url('/src/assets/fonts/AvenirLTStd-Book.otf') format('opentype');
}

@font-face {
    font-family: 'Avenir Roman';
    src: url('/src/assets/fonts/AvenirLTStd-Roman.otf') format('opentype');
}
