.paymentListMethods_outer{
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  .payment_row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 14px 16px;
    border-radius: 12px;
    .left{
      display: flex;
      align-items: center;
    }
  }
  .round_c{
    width: 12px;
    min-width: 12px;
    height: 12px;
    display: inline-flex;
    border: 1px solid #eee;
    border-radius: 50%;
    margin-right: 10px;
  }
  .is_default.payment_row{
    .round_c{
      background: var(--chakra-colors-brand);
    }
  }
  .text_default{
    font-size: 14px;
    font-weight: 600;
    color:#ccc;
  }
  .make_default{
    color: var(--chakra-colors-brand);
    cursor: pointer;
  }
}