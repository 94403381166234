.ql-container.ql-snow {
    min-height: 120px;
    padding: 8px 0px;
    border-radius: 24px;
    background: #F6F7FB;
    font-size: 16px;
    color: #2C335C;
    border: none !important ;
    font-family: 'Poppins' !important;
}
.ql-toolbar.ql-snow {
    border: none !important;
    font-family: 'Poppins' !important;
}
.ql-editor.ql-blank::before {
    color: #888DAB;
}
