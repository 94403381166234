body {
  --color-text: #2C335C;
}

.box_center {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.flex_full{
  flex: 1;
}

.fix_sidebar_button{
  outline: unset !important;
  box-shadow: unset !important;
  .chakra-button__icon{
    margin-right: 12px;
  }
}
a{
  outline: unset !important;
}

.HOME_REQUESTS, .HOME_MY_REQUESTS, .HOME_BLASTS{
  box-shadow: 0px 0px 10px 0px rgba(44, 51, 92, 0.13);
  background: #fff;
  border-radius: 10px;
  .title{
    padding: 26px 24px;
    border-bottom: 1px solid rgba(235, 242, 248, 1);
    color: #2C335C;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: left;
    display: flex;
    align-items: center;
    .left{
      display:flex;
      align-items: center;
    }
    .right{
      position: relative;
      .small_btn{
        position: absolute;
        width: 40px;
        height: 40px;
        background: #FFF4F1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        top: -18px;
        right: -2px;
        cursor: pointer;
      }
    }
    justify-content: space-between;
  }
  .item_row{
    border-bottom: 1px solid rgba(235, 242, 248, 1);
  }
  .radi_home_title {
    margin-left: 50px;
  }
  .bottom_panel{
    padding: 22px 20px;
    color: #FE805C;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top:1px solid rgb(235, 242, 248);
    &.border_tb{
      border-top:1px solid rgb(235, 242, 248);
    }
  }
}

.fix_row_request{
  .min_box{
    margin: 0px;
  }
  .ac_row_available{
    margin:0px;
    background: #fff;
    padding-right: 4px;
  }
}

.home_row_cell{
  margin-bottom: 20px;
}

.border_cell_request_box {
  min-width: 105px !important;
}

.HOME_MY_REQUESTS{
  .REQUEST_BOX_COMPONENT {
    margin:0px;
  }

  .reques_box_inner{
    background: #fff;
  }
}

.blast_cell{
  display:flex;
  flex-direction: column;
  padding: 16px 20px;
  .top_panel{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .left{
    display: flex;
    align-items: center;
  }
  .user_name{
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.01em;
    margin-left: 15px;
    color: var(--color-text);
  }
  .image_cell{
    overflow: hidden;
    width: 65px;
    min-width: 65px;
    max-width: 65px;
    height: 65px;
    border-radius: 10px;
    margin-right: 16px;;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .contentCenter{
    display: flex;
    cursor: pointer;
    .right{
      flex: 10;
    }
  }
  .tile_name{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;    
  }
}

.menu_title{
  margin: 32px 0px 16px !important;
  color: #3C4578;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-transform: uppercase;
  width: 100%;
  padding-left: 18px;
}

.big_btn{
  margin-bottom: 32px;
  padding: 15px;
  border-radius: 15px;
  background: #2C335C;
  text-align: center;
  width: 100%;
  color:#fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
}

.pointer{
  cursor: pointer;
}

.no_found{
  font-size: 20px;
  margin-top: 10px;
}

.empty_grid{
  margin: 12px 0px;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}

.radi_home_title {
  .chakra-radio__label{
    font-size: 16px;
  }
}

.cell_blast_link_ads{
  border: 1px solid #eee;
  border-radius: 0px 0px 20px 20px;
}

.cell_blast_link_ads2{
  border: 1px solid #eee;
}

.box_relative{
  position: relative;
}

.unread_info{
  position: absolute;
  right: 10px;
  color: #fff;
  background: #FE805C;
  display: flex;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 11px;
  right: -30px;
}

.outer_btns_accept{
  flex:1;
  .BTN_CANSEL_REQUEST{
    flex: 1;
    padding: 0px;
    width: auto;
  }
  .BTN_ACCEPT_REQUEST{
    flex: 1;
    padding: 0px;
    width: auto;
  }
}

.user_found{
  font-size: 12px;
  margin: 12px 0px 0px 0px !important;
  color: #841010;
  display: block;
  a{
    color: #2C335C;
    display: inline-flex;
    margin-left: 5px;
    text-decoration: underline;
    font-weight: 500;
  }
}

.fix_sidebar_button{
  &.active_btn{
    color: #FE805C;
  }
}

.icon_fix{
  background-color: #2C335C;
  mask-repeat: no-repeat;
  mask-size: 100%;
  &.active{
    background-color: #FE805C;
  }
}

.fix_sidebar_button {
  border-radius: 12px !important;
  height: 42px !important;
}

.subscription_page_contaner{
  overflow: hidden;
  justify-content: center;
  display: flex;

  .subscription_page{
    max-width: 940px;
    width: 100%;
    display: flex;
    margin: 0px -10px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18.29px;
    text-align: left;    
    max-width: 940px;
    .left_column {
      margin: 16px;
      border: 0px solid #000;
      flex: 1;
      padding: 24px 24px 0px 24px;
      background: rgba(246, 246, 248, 1);
      border-radius: 16px;
      display: flex;
      align-items: flex-start;
      color: rgba(44, 51, 92, 1);
      flex-direction: column;
      .icon_check{
        margin-right: 10px;
      }
      .info_row{
        margin-bottom: 24px;
        display: flex;
        align-items: center;
      }
    }
    .right_column{
      margin: 16px;
      border: 3px solid rgba(254, 128, 92, 1);
      flex: 1;
      background: rgba(255, 247, 245, 1);
      border-radius: 16px;
      padding: 24px;
      text-align: center;
      .title_col{
        font-size: 22px;
        font-weight: 700;
        line-height: 26.82px;
        margin-bottom: 16px;
        .status{
          color: #4FD1C5;
          margin-left: 10px;
          &.notactive{
            color: red;
          }
        }
        
      }
      .desc_1{
        margin-bottom: 10px;
        &.expired{
          color: red;
        }
        &.free{
          color: rgba(254, 128, 92, 1);
        }
      }
      .image_block{
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        img{
          max-width: 100%;
        }
      }
      .switcher_c {
        display: flex;
        align-items: center;
        justify-content: center;
        .chakra-switch{
          height: 20px;
        }
        .chakra-switch__track{
          background: rgba(79, 70, 229, 1);
          box-shadow: unset;
        }
        .left_label, .right_label{
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: right;
          min-width: 150px;
          display: inline-flex;
          .rl_bold{
            color: #4FD1C5;
            font-weight: 700;
            display: inline-flex;
            margin-left: 5px;
          }
        }
        .left_label{
          justify-content: flex-end;
          margin-right: 12px;
        }
        .right_label{
          justify-content: flex-start;
          margin-left: 12px;
        }
      }
      .title_2{
        color: rgba(44, 51, 92, 1);
        margin: 24px 0px;
        font-size: 22px;
        font-weight: 700;
      }
    }
  }

  .btn_subscribe, .btn_unsubscribe{
    background: rgba(79, 209, 197, 1);
    padding: 16px 46px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    min-width: 250px;
    justify-content: center;
    text-align: center;
  }
  .btn_unsubscribe{
    background: #D1D2DB;
  }
  .priceBlock{
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: rgba(44, 51, 92, 1);
    display: flex;
    align-items: flex-end;
    line-height: 100%;
    justify-content: center;
    margin-bottom: 24px;
    .postfix{
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      text-align: center;
      
    }
  }
}

.image_relative{
  position: relative;
}

.icon_home_m{
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.cover_image{
  object-fit: cover;
}

.ACTIVE_H {
  background: rgba(255, 235, 230, 0.8) !important;
}

.info_icon_rates{
  color: #aaa;
  font-size: 16px;
  margin: 0px 6px 4px;
  display: inline-flex;
  cursor: pointer;
}

div[role=tooltip]{
  padding: 10px;
  border-radius: 10px;
}

.new_home_landing{

  .landing_top{
    background-color: #000;
  }

}

.btn_fresh_listings{
  cursor: pointer;
  color: var(--chakra-colors-brand);
  font-weight: 600;
  .cion{
    margin-right: 5px;
  }
  display: flex;
  align-items: center;
}

.date_loaded_l{
  color: #999;
  font-size: 12px !important;
  margin-right: 10px;
}

.flex_row_auth{
  box-sizing: border-box;
  max-width: 100%;
  *{
    box-sizing: border-box;
    max-width: 100%;
  }
  display:flex;
  color: #2C335C;
  font-size: 16px;
  font-weight: 400;
  line-height: 141.9%;
  .left_col{
    width: 460px;
    margin-right: 50px;
    max-width: 100%;
  }
  .right_col{
    width: 460px;
    margin-left: 50px;
    align-items: center;
    display: flex;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    .rc_inner{
      width: 100%;
    }
  }
  .title_1{
    color: #2C335C;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 29px;
  }
  .title_2{
    margin-bottom: 16px;
    font-size: 34px;
    font-weight: 700;
    line-height: 125.1%;
  }
  .desc{
    margin-bottom: 40px;
  }
  .markers{
    display: flex;
    flex-direction: column;
    .marker_one{
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      text-align: left;
      .icon_a{
        display: inline-flex;
        margin-right: 18px;;
      }
    }
  }
  .auth_left{
    margin-top: 100px;
  }
  .auth_right{
    display: none;
    width: 100%;
    margin-top: 30px;
  }
  .auth_left{
    display: block;
  }
}

@media (max-width:992px) {
  .fixed_form{
    max-width: 100%;
    box-sizing: border-box;
  }
  .fixed_m_box{
    padding: 18px !important;
    max-width: 100%;
  }
  .flex_row_auth{
    flex-direction: column;
  }
  .left_col{
    margin-left: 0px !important;
    margin-bottom: 26px;
  }
  .right_col{
    margin: 0px !important;
  }
  .auth_right{
    display: block !important;
  }
  .auth_left{
    display: none !important;
  }
}

.top_panel_header{
  display: flex;
  background: #F0F8FF;
  padding: 12px 0px;
  .innertph{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .socials{
    display: flex;
    li{
      list-style: none;
      margin-right: 24px;
    }
  }
  .right{
    .link_outer{
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 175%;
      padding: 0px 16px;
      margin-left: 32px;
    }
  }
}

.flex_tm_center{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.o_cp{
  overflow: hidden;
  .center_menu_panel{
    display: flex;
    margin: 0px -44px;
    a{
      margin: 0px 44px;
    }
  }
}

@media (max-width: 1070px){
  .o_cp{
    display: none;
  }
  .top_panel_header{
    display: none;
  }
}

.state_text{
  font-size: 10px;
  display: inline-flex;
  margin-left: 5px;
}

.payment_row {
  .right{
    display: flex;
    align-items: center;
  }
  .del_icon_p{
    margin-left: 5px;
    cursor: pointer;
  }
}

.red_btn{
  background: #DC0000 !important;
  color: #fff !important;
}

.right_btn_flp{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.relative_fe{
  position: relative;
  align-items: flex-end;
}

.ncc_req{
  color: #FFAC1C;
  text-align: center;
  position: static;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: -10px;
  margin-top: 12px;
}

.label_ag{
  select{
    width: 100%;
  }
}

.agency_flex{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.g-auth-outer{
  margin: 30px 0px;
}

.google_icon_outer{
  display: flex;
  width: auto;
  padding: 10px;
  height: auto;
  background: #fff;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  margin: 0px;
  cursor: pointer;
  width: 100%;
  border: 2px solid #DFE4EC;
  position: relative;
  height: 56px;
  align-items: center;
  text-align: center;

  color: #2C335C;
  font-size: 16px;
  font-weight: 400;
  .icon_outer{
    position: absolute;
    left: 10px;
  }
  &:hover{
    background: #f7f7f7;
  }
}

.modal_first_nameln{
  background: #fff !important;
  .desc_title{
    margin-top:20px;
    color: #2C335C;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 141.9%; /* 22.704px */
  }
  .form_fl{
    padding: 40px 40px 30px;
    @media(max-width: 768px){
      padding: 30px 10px 20px;
    }
  }
}

.warning_license{

}

.flex-center{
  display: flex;align-items: center;
}

.user_warning_pane{
  cursor: pointer;
  color: orange;
  display: flex;
  align-items: center;
  .info_icon_outer{
    margin-right: 10px;
  }
}

.wraper_cpn{
  .slot_val{
    
  }
}

.modal_confirm_phone{
  background: #fff !important;
  .desc_title{
    margin-top:20px;
    color: #2C335C;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 141.9%; /* 22.704px */
  }
  .form_fl{
    padding: 40px 40px 30px;
    @media(max-width: 768px){
      padding: 30px 10px 20px;
    }
  }
}

.no_license_block{
  padding: 64px 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px){
    padding: 20px;
  }
  .title{
    color: #2C335C;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 40px;
  }
  .btn_click{
    display: flex;
    max-width: 100%;
    width: 342px;
    padding: 16.136px 0px 15.864px 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    background: #2C335C;
    color: #fff;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    margin-bottom: 56px;
  }
  .center_info{
    width: 100%;
  }
  .info_row{
    margin-bottom: 24px;
    .if_title{
      margin-bottom: 8px;
      color: #2C335C;
      font-size: 20px;
      font-weight: 700;
    }
    .if_desc{
      color: #2C335C;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.accordion_fix{
 .chakra-accordion__item{
  border: 0px;
  margin-bottom: 32px;
 } 
 .chakra-accordion__button{
  background: transparent !important;
  padding: 0px;
  margin-bottom: 10px;
  position: relative;
  outline: unset;
  box-shadow: unset !important;
  span{
    color: #2C335C;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    outline: unset;
  }
  .accordion_btn {
    position: absolute;
    left: -39px;
  }
 }
 .chakra-accordion__panel{
  padding: 0px;
 }
}

.or_btn{
  margin: 0px 0px 24px;
  display: flex;
  justify-content: center;
  color: #2C335C;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 134.8%; /* 18.872px */
}

.message_not_approved{
  color: #ca8300;
  font-size: 17px;
}

.dm_content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 0px;
  .left{
    width: 100%;
  }
  .right{
    width: 100%;
  }
}

.inline_flex{
  display: inline-flex;
}

button{
  outline: unset !important; 
  box-shadow: unset !important;
}

.right_block_m{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.main_menu_modal_body{
  padding: 10px 16px;
  height: 100%;
  overflow: auto;
  .sidebar_mobile_main_menu{
    .sidebar_box_main {
      max-width: 100% !important;
      min-width: 100%!important;
      width: 100% !important;
      padding: 6px 0px;
      .menu_stack{
        width: 100%;
        .fix_sidebar_button  {
          width: 100%;
          padding: 0px 10px;
        }
      }
      .big_btn {
        margin-bottom: 20px;
      }
    }
  }
}

.right_block_m{
  .icon_menu{
    margin-right: 10px;
  }
  .currentBalance{
    color: black;
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}

.chakra-popover__content{
  max-width: 100% !important;
  box-sizing: border-box !important;
}
.chakra-popover__popper{
  width: auto;
  max-width: 100%;
  overflow: hidden;
  min-width: unset !important;
}

.fwidth{
  width: 100%;
}


@media(max-width:1120px){
  .sidebar_left  {
    display: none;
  }
  .box_right_container {
    max-width: 100%;
  }
  .off_market_body {
    .box_right_container {
        max-width: 100% !important;
    }
  }
  .off_market_body {
    .DASHBOARD_HEADER_COMPONENT  {
      margin-bottom: 0px !important;
    }
  }
  .main_conttainer_app{
    margin-bottom: 20px;
  }
  .btns_flex{
    flex-direction: column;
  }
  .flex_cell{
    width: 100%;
    margin-bottom: 20px;
    & > div{
      margin: 0px !important;
    }
    & > a > div{
      margin: 0px !important;
    }
  }
  .hm_box{
    width: calc(100% - 53px);
  }
}

.change_email_btn{
  color: var(--chakra-colors-green);
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

.count_om_messages{
  position: relative;
  z-index: 1;
  .n_cofcm{
    position: absolute;
    top: -7px;
    right: -8px;
    background: #ce3030;
    display: flex;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
  }
}